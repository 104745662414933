import React, { useState, useContext, useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import isNode from 'detect-node';
import styled from 'styled-components';
import { media } from 'Src/app/Styles/Theme';
import MainContext from 'Src/app/MainContextProvider';
import Header from 'Components/TG/TG01__Header';
import Locales from 'Locales';

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  ${media.laptop`
    width: 944px;
  `};
  ${media.desktop`
    width: 1224px;
  `};
  ${media.widescreen`
    width: 1384px;
  `};
  ${media.ultrawidescreen`
    width: 1416px;
  `};
`;

const TopbarWrapper = styled.div`
  width: 100%;
  padding: 0 16px;
  ${media.laptop`
    width: 944px;
    padding: 0 0 46px;
  `};
  ${media.desktop`
    width: 1224px;
  `};
  ${media.widescreen`
    width: 1384px;
    padding: 0 0 46px;
  `};
  ${media.ultrawidescreen`
    width: 1416px;
    
  `};
`;

const Topbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.secondary_petrol};
  padding: 32px 0;
  ${media.laptop`
    width: 944px;
    padding: 48px 0 0;
  `};
  ${media.desktop`
    width: 1224px;
  `};
  ${media.widescreen`
    width: 1384px;
    padding: 56px 0 0;
  `};
`;

const Title = styled.h1`
  margin: 0;
  font-size: 23px;
  line-height: 40px;
  letter-spacing: 0.02em;
  ${media.laptop`
    font-size: 40px;
    line-height: 50px;
  `};
`;

const Statistics = () => {
  const {
    state: { token, lang, users },
    dispatch,
  } = useContext(MainContext);

  const [isAccessEmail, setAccessEmail] = useState(false);

  useEffect(() => {
    if (token) {
      const { name, sub, email } = jwtDecode(token);
      dispatch({ type: 'username', payload: name });
      dispatch({ type: 'email', payload: email });
      dispatch({ type: 'userId', payload: sub });

      setAccessEmail(users.some(item => item.toLowerCase() === email));

      dispatch({
        type: 'isShowStatisticsInMenu',
        payload: users.some(item => item.toLowerCase() === email),
      });

      if (!isNode) {
        window.dataLayer_kdx = window.dataLayer_kdx || [];
        window.dataLayer_kdx.push({ userId: sub });
      }
    }
  }, [token]);

  return (
    <>
      <Header page="statistics" />
      <Container>
        <TopbarWrapper>
          <Topbar>
            <Title>{Locales[lang].Statistics.title}</Title>
          </Topbar>
        </TopbarWrapper>{' '}
        {isAccessEmail && (
          <iframe
            title="statistics"
            width="100%"
            src="https://datastudio.google.com/embed/reporting/0296db01-5f22-42c2-986e-184fb6e0727f/page/0t8EB"
            frameBorder="0"
            allowFullScreen
            style={{ height: '100vh' }}
          />
        )}
      </Container>
    </>
  );
};

export default Statistics;
