import React from 'react';
import App from 'Src/app/';
import Statistics from 'Components/Statistics';
import Display from 'Components/Display';

const StatisticsPage = () => (
  <App lang="en">
    <Display>
      <Statistics />
    </Display>
  </App>
);

export default StatisticsPage;
